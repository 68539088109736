import { css, keyframes } from 'styled-components';

export const sizes = {
  giant: 1160,
  desktop: 1024,
  smallDesktop: 992,
  tablet: 767,
  phone: 376
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media screen and (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const animationMoema1 = keyframes`
  60% {
    transform: scale3d(0.8, 0.8, 1);
  }
  85% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

export const animationMoema2 = keyframes`
  to {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
`;

export const fadeIn = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;

export const slide = keyframes`
  100% { left: 0; }
`;
