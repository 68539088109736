import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  width: 48px;
  height: 48px;
  .circle {
    fill: #666666;
    opacity: 0.25;
  }
  .line {
    stroke: #fff;
    stroke-linecap: round;
    stroke-width: 5px;
  }
`;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon viewBox="0 0 95.5 95.5" className={className}>
      <circle className="circle" cx="47.75" cy="47.75" r="46.25" />
      <line className="line" x1="23.75" y1="57.75" x2="47.25" y2="31" />
      <line className="line" x1="71.25" y1="57.75" x2="47.25" y2="31" />
    </StyledIcon>
  </Wrapper>
);
