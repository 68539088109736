import GatsbyLink from 'gatsby-link';
import React from 'react';

export default function Link(props) {
  const { href, to, label, hideBorder, children, ...others } = props;
  if (to) {
    return <GatsbyLink {...others} to={to}>{children}</GatsbyLink>;
  } else {
    delete others.loading;
    return <a {...others} href={href} aria-label={label}>{children}</a>;
  }
}
