import { injectGlobal } from 'styled-components';

injectGlobal`
  * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    margin: 0;
    padding: 0;
  }
`;