import Header from './Header';
import {
  compose,
  withStateHandlers,
  onlyUpdateForKeys,
  lifecycle,
} from 'recompose';

const stateHandlers = withStateHandlers(
  () => ({
    threshold: 40,
    atTop: true
  }),
  {
    handleScroll: ({ atTop, threshold }) => value => {
      const top =
        document.documentElement.scrollTop ||
        document.body.parentNode.scrollTop ||
        document.body.scrollTop;
      const atTop = top < threshold;
      return { atTop };
    },
  }
);

export default compose(
  stateHandlers,
  lifecycle({
    componentDidMount() {
      window.addEventListener('scroll', this.props.handleScroll);
    },
    componentWillUnmount() {
      window.removeEventListener('scroll', this.props.handleScroll);
    }
  }),
  onlyUpdateForKeys(['atTop', 'showMenu'])
)(Header);
