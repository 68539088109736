import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/styles';
import BackToHome from '../components/Icons/BackToHome';

const BackToTop = styled.button`
  background: 0 0;
  border: none;
  padding: 0;
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  opacity: ${props => (props.atTop ? '0' : '1')};
  transition: opacity 400ms;
  z-index: 999;
  height: 52px;
  width: 52px;

  ${media.tablet`
    right: 30px;
    bottom: 10px;
  `};
`;

class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = { threshold: 40, intervalId: 0, atTop: true };
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    this.bindScroll();
  }

  componentWillUnmount() {
    this.unbindScroll();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.atTop !== nextState.atTop;
  }

  scrollStep() {
    if (this.state.atTop) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  bindScroll() {
    let supportsPassive = false;
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          supportsPassive = true;
          return true;
        }
      });
      window.addEventListener('test', null, opts);
    } catch (e) {} // eslint-disable-line no-empty
    window.addEventListener(
      'scroll',
      this.handleScroll,
      supportsPassive ? { passive: true } : false
    );
  }

  unbindScroll() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    const top =
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop;
    if (top < this.state.threshold) {
      this.setState({ atTop: true });
    } else {
      this.setState({ atTop: false });
    }
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <BackToTop
        atTop={this.state.atTop}
        onClick={this.scrollToTop}
        label="Back to top"
        aria-label="Back to top"
        aria-hidden="true"
      >
        <BackToHome />
      </BackToTop>
    );
  }
}

export default ScrollToTop;
