
export const pseudoElementScript = () => {
  const pseudoScript = document.createElement('script');
  pseudoScript.innerHTML = `
    var UID = {
      _current: 0,
      getNew: function(){
        this._current++;
        return this._current;
      }
    };
    HTMLElement.prototype.pseudoStyle = function (element,prop,value) {
      var _this = this;
      var _sheetId = "pseudoStyles";
      var _head = document.head || document.getElementsByTagName('head')[0];
      var _sheet = document.getElementById(_sheetId) || document.createElement('style');
      _sheet.id = _sheetId;
      var className = "pseudoStyle" + UID.getNew();
      _this.className +=  " "+className; 
      _sheet.innerHTML += " ."+className+":"+element+"{"+prop+":"+value+"}";
      _head.appendChild(_sheet);
      return this;
    };
  `;
  document.head.appendChild(pseudoScript);
}

export const startsWithScript = () => {
  const startsWithScript = document.createElement('script');
  startsWithScript.innerHTML = `
    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
      };
    }
  `;
  document.head.appendChild(startsWithScript);
}