import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/styles';

const StyledMenu = styled.svg`
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  fill: #0071af;
  transition: fill 0.1s;

  &:hover {
    fill: #00a0e1;
  }

  ${media.tablet`
    margin-left: 8px;
  `} ${media.desktop`
    margin-left: 10px;
  `};
`;

const Wrapper = styled.span``;

export default ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <StyledMenu viewBox="-1 0 40 36" width="42" height="42">
      <rect className="cls-1" x="8" y="10" width="21.88" height="2" />
      <rect className="cls-1" x="8.12" y="17" width="21.88" height="2" />
      <rect className="cls-1" x="8.12" y="24" width="21.88" height="1.92" />
      <path
        className="cls-1"
        d="M28.05,1.67A8.28,8.28,0,0,1,36.33,10v16.1a8.28,8.28,0,0,1-8.28,8.28H10a8.28,8.28,0,0,1-8.28-8.28V10A8.28,8.28,0,0,1,10,1.67h18.1m0-1.67H10A9.94,9.94,0,0,0,0,10v16.1A9.94,9.94,0,0,0,10,36h18.1a9.94,9.94,0,0,0,10-9.95V10A9.94,9.94,0,0,0,28.05,0Z"
      />
    </StyledMenu>
  </Wrapper>
);
