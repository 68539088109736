import React from "react";
import styled from "styled-components";
import { media } from "../../utils/styles";
import Link from "../../elements/Link";
import CloseButton from "../Icons/CloseMenu";

const Nav = styled.nav`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.primary};
  position: fixed;
  top: 0;
  right: ${props => (props.active ? '0' : '-110%')};
  z-index: 999;
  -webkit-transition: right 400ms;
  transition: right 400ms;
  overflow-y: auto;

  ${media.tablet`
    width: 300px;
    right: ${props => (props.active ? '0' : '-350px')};
  `};
`;

const Menu = styled.ul`
  list-style-type: none;
  margin: 24px 30px 0;
  padding: 0;
`;

const MenuItem = styled.li`
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #f15a76;
`;

const MenuLink = styled(Link)`
  font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.075em;
  line-height: 47px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  ${media.desktop`
    line-height: 50px;
  `}

  &:hover {
    color: #f9bfca;
  }
`;

const MobileOnlyMenuItem = styled(MenuItem)`
  display: block;

  ${media.tablet`
    display: none;
  `}
`;

export default ({
  active,
  onToggleMenu
}) => {
  return (
    <Nav active={active}>
      <CloseButton onClick={onToggleMenu} />
      <Menu>
        <MenuItem>
          <MenuLink to="/#home" onClick={onToggleMenu}>Home</MenuLink>
        </MenuItem>
        <MobileOnlyMenuItem>
          <MenuLink href={process.env.GATSBY_SIGNUP_URL}>
            Sign Up For Free
        </MenuLink>
        </MobileOnlyMenuItem>
        <MobileOnlyMenuItem>
          <MenuLink href={process.env.GATSBY_LOGIN_URL}>
            Login
          </MenuLink>
        </MobileOnlyMenuItem>
        <MenuItem>
          <MenuLink to="/#expert-advice-you-can-trust" onClick={onToggleMenu}>Expert Advice</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/#meet-our-experts" onClick={onToggleMenu}>Meet our Experts</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/#bundle-of-benefits" onClick={onToggleMenu}>A Bundle of Benefits</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/#take-us-with-you" onClick={onToggleMenu}>Take us with you</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/#our-subscriptions" onClick={onToggleMenu}>Our Subscriptions</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/#testimonials" onClick={onToggleMenu}>Testimonials</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to="/articles" onClick={onToggleMenu}>
            Expert articles
        </MenuLink>
        </MenuItem>
      </Menu>
    </Nav>
  )
};
