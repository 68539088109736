import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 35px;
  cursor: pointer;
  width: 19.5px;
  height: 19.5px;
  fill: #fff;

  &:hover {
    fill: #f9bfca;
  }
`;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon viewBox="0 0 512 512" width={35} height={35} className={className}>
      <path d="m505.94 6.058c-8.077-8.077-21.172-8.077-29.249 0l-470.64 470.64c-8.077 8.077-8.077 21.172 0 29.249 4.038 4.04 9.332 6.058 14.625 6.058s10.586-2.019 14.625-6.059l470.64-470.64c8.076-8.076 8.076-21.171 0-29.248z" />
      <path d="m505.94 476.69l-470.64-470.64c-8.076-8.077-21.172-8.077-29.248 0-8.077 8.076-8.077 21.171 0 29.248l470.64 470.64c4.038 4.039 9.332 6.058 14.625 6.058s10.587-2.019 14.624-6.057c8.075-8.078 8.075-21.173-1e-3 -29.25z" />
    </StyledIcon>
  </Wrapper>
);
