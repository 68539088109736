import React from 'react';
import styled from 'styled-components';
import Facebook from '../Icons/Facebook';
import Twitter from '../Icons/Twitter';
import Blog from '../Icons/Blog';
import Email from '../Icons/Email';
import ScrollToTopButton from '../../elements/ScrollToTopButton';
import { StaticQuery, graphql } from 'gatsby';
import Logo from '../Icons/Logo';

const ALL_RIGHTS_RESERVED_MSG = `© ${(new Date()).getFullYear()} My Learning Baby (Pty) Ltd. All Rights Reserved`;
const TERMS_AND_CONDITIONS_MSG = `Terms and Conditions`;
const PRIVACY_MSG = `Privacy Policy`;

const Footer = styled.footer`
  background-color: ${props => props.theme.primary};
  width: 100%;
  display: flex;
  justify-content: center;
  user-select: none;

  @media (max-width: 940px) {
    padding-bottom: 20px;
  }
`;

const StyledLogo = styled(Logo)`
  fill: white;
  width: 133px;
`;

const Wrapper = styled.div`
  width: 96%;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 45px 0;

  @media (max-width: 940px) {
    flex-direction: column;
    padding: 10px 0 15px 0;
  }

`;

const Bottom = styled.div`
  height: 70px;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  color: white;
  font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;

  @media (max-width: 940px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

const Rights = styled.div`
  @media (max-width: 940px) {
    margin-bottom: 10px;
  }
`;

const ServiceLinks = styled.div`
  @media (max-width: 940px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const LogoWrapper = styled.div`

`;

const AppLinks = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  padding: 0 4%;

  @media (max-width: 1000px) {
    padding: 0 2%;
    justify-content: space-between;
  }

  @media (max-width: 940px) {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

const AppLink = styled.a`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  transition: border-bottom ease 0.5s;

  &:hover {
    border-bottom: 2px solid white;
  }

  @media (max-width: 940px) {
    margin-top: 15px;
  }
`;

const Servicelink = styled.a`
  margin: 0 0 0 15px;
  color: white;
  text-decoration: none;

  @media (max-width: 940px) {
    margin-bottom: 10px;
  }
`;

const SocialLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

const SocialLink = styled.li`
  margin-left: 15px;

  & svg {
    width: 45px;
    height: 45px;
  }
`;

const appLinks = [{
  label: 'Home',
  to: '/'
},{
  label: 'View expert articles',
  to: '/articles'
},{
  label: 'The science',
  to: '/landing/what-is-baby-stimulation'
},{
  label: 'Login',
  to: 'https://app.nubabi.com/login?redirect=/profile'
},{
  label: 'Sign up',
  to: 'https://app.nubabi.com/signup'
}];

const renderAppLink = ({label, to}) => <AppLink key={label} href={to}>{label}</AppLink>

export default ({ children, data, showMenu, toggleMenu }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulSiteConfig {
          facebookUrl
          twitterHandle
          blogUrl
          contactEmail
        }
      }
    `}
    render={({
      toggleMenu,
      showMenu,
      contentfulSiteConfig: {
        facebookUrl: facebook,
        contactEmail: email,
        twitterHandle: twitter,
        blogUrl: blog,
      }
    }) => {
    return (
      <Footer>
        <Wrapper>
          <Top>
            <LogoWrapper>
              <StyledLogo />
            </LogoWrapper>
            <AppLinks>
              {appLinks.map(renderAppLink)}
            </AppLinks>
            <SocialLinks>
          <SocialLink>
            <a
              href={facebook}
              name="Facebook"
              area-label="View our Facebook page"
            >
              <Facebook />
            </a>
          </SocialLink>
          <SocialLink>
            <a href={`https://twitter.com/${twitter.toLowerCase()}`} name="Twitter" area-label="View our Twitter page">
              <Twitter />
            </a>
          </SocialLink>
          <SocialLink>
            <a
              href={blog}
              name="ViewExpertArticles"
              area-label="View our Expert Articles"
            >
              <Blog />
            </a>
          </SocialLink>
          <SocialLink>
            <a href={`mailto:${email}`} name="EmailUs" area-label="Email Us">
              <Email />
            </a>
          </SocialLink>
        </SocialLinks>
          </Top>
          <Bottom>
            <Rights>{ALL_RIGHTS_RESERVED_MSG}</Rights>
            <ServiceLinks>
              <Servicelink href="/terms">{TERMS_AND_CONDITIONS_MSG}</Servicelink>
              <Servicelink href="/privacy">{PRIVACY_MSG}</Servicelink>
            </ServiceLinks>
          </Bottom>
          <ScrollToTopButton scrollStepInPx="80" delayInMs="10" />
        </Wrapper>
      </Footer>
    );
  }} />
);

