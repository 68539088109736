import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { media } from '../../utils/styles';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../utils/theme';
import Header from '../Header';
import Footer from '../Footer';

import './injectGlobal';

const Main = styled.div`
  outline: 0;
`;

const BodyWrap = styled.div`
  position: relative;
  top: 0;
  right: ${props => (props.showMenu ? '100%' : '0')};
  transition: right 400ms;
  backface-visibility: hidden;

  ${media.tablet`
    padding-top: 0;
    right: ${props => (props.showMenu ? '300px' : '0')};
  `};
`;

export default ({ children, data, showMenu, toggleMenu, canonicalUrl }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulSiteConfig {
          name
          tagLine
          siteDescription
        }
      }
    `}
    render={({ contentfulSiteConfig: config }) => {
      return <>
          <Helmet
            defaultTitle={config.name}
            titleTemplate={`%s | ${config.name}`}
            link={[
              { rel: 'dns-prefetch', href: 'https://use.typekit.net'},
              { rel: 'canonical', href: canonicalUrl }
            ]}
          >
            <meta name="description" content={config.siteDescription} />
            <meta name="robots" content={process.env.GATSBY_ENABLE_ROBOTS === 'true' ? 'all' : 'noindex, nofollow'} />
            <html lang="en" />
          </Helmet>
          <ThemeProvider theme={theme}>
            <>
              <Header canonicalUrl={canonicalUrl} showMenu={showMenu} onToggleMenu={toggleMenu} />
              <BodyWrap showMenu={showMenu}>
                <Main>{children}</Main>
                <Footer />
              </BodyWrap>
            </>
          </ThemeProvider>
        </>;
    }}
  />
);
