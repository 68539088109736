import {
  withStateHandlers,
  compose,
  lifecycle,
} from 'recompose';
import Layout from './Layout';
import { startsWithScript } from '../../utils/headerScripts';

export default compose(
  lifecycle({
    componentDidMount() {
      if (typeof window !== "undefined") {
        const WebFont = require('webfontloader');
        WebFont.load({ typekit: { id: process.env.GATSBY_TYPEKIT_ID } });
      }
      startsWithScript();
    }
  }),
  withStateHandlers(
    () => ({ showMenu: false }),
    {
      toggleMenu: ({ showMenu }) => value => {
        return { showMenu: !showMenu };
      }
    }
  )
)(Layout);