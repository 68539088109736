import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { media } from '../../utils/styles';
import Logo from '../Icons/Logo';
import { NavButton, TransparentNavButton, MobileNavButton } from '../../elements/Buttons';
import MenuIcon from '../Icons/Menu';
import Nav from './Nav';

const Header = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  right: ${props => (props.showMenu ? '100%' : 0)};
  z-index: 997;
  background-color: ${props =>
    props.atTop ? 'transparent' : 'rgba(255, 255, 255, 0.97)'};
  transition: right 400ms, background-color 400ms;
  padding: 10px 0;
  border-bottom: ${props => (props.atTop ? 'none' : '1px solid #f4f5f6')};

  ${media.tablet`
    right: ${props => (props.showMenu ? '300px' : 0)};
  `};
`;

const StyledLogo = styled(Logo)`
  margin-left: 0;
  vertical-align: middle;
  float: left;

  ${media.tablet`
    width: 131px;
    margin: 0;
  `} ${media.desktop`
    width: 150px;
  `};
`;

const Wrapper = styled.div`
  text-align: right;
  height: ${props => (props.atTop ? '48px' : '60px')};
  padding-top: ${props => (props.atTop ? '5px' : '10px')};
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  transition: height 300ms, padding 300ms;

  ${media.tablet`
    position: relative;
    z-index: 99;
    height: ${props => (props.atTop ? '100px' : '75px')};
    padding-top: ${props => (props.atTop ? '43px' : '20px')};
    padding-bottom: ${props => (props.atTop ? '43px' : '20px')};
    max-width: 750px;
    margin: 0 auto;
  `}

  ${media.smallDesktop`
    max-width: 900px;
  `}

  ${media.desktop`
    max-width: 970px;
    height: ${props => (props.atTop ? '100px' : '78px')};
    padding-top: ${props => (props.atTop ? '38px' : '20px')};
    padding-bottom: ${props => (props.atTop ? '38px' : '20px')};
  `}

  ${media.giant`
    max-width: 1183px;
  `}
`;

const Links = styled.div`
  display: none;

  ${media.tablet`
    display: inline-block;
    vertical-align: middle;
  `};
`;

export default ({ atTop, showMenu, onToggleMenu, canonicalUrl }) => (
  <>
    <Header atTop={atTop} showMenu={showMenu}>
      <Nav active={showMenu} onToggleMenu={onToggleMenu} />
      <Wrapper atTop={atTop}>
        <Link to="/" aria-label="Back to Home">
          <StyledLogo />
        </Link>
        <Links>
          <TransparentNavButton to="/articles" label="Read our Expert Articles" hideBorder={true}>
            View our Expert Articles
              </TransparentNavButton>
          <NavButton href={process.env.GATSBY_SIGNUP_URL} label="Sign up for Free">
            Sign up for Free
              </NavButton>
          <TransparentNavButton href={process.env.GATSBY_LOGIN_URL} label="Login">
            Login
              </TransparentNavButton>
        </Links>
        <MobileNavButton url={canonicalUrl} href={process.env.GATSBY_SIGNUP_URL} label="Sign up">Sign Up</MobileNavButton>
        <MenuIcon onClick={onToggleMenu} />
      </Wrapper>
    </Header>
  </>
) 
