import React from "react";
import Link from "../Link";
import styled from "styled-components";
import { media, sizes } from "../../utils/styles";

const Button = styled(Link)`
  font-family: 'proxima-soft', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.075em;
  color: #fff;
  margin-left: 10px;
  padding: 15px 29px;
  border: 2px solid ${props => props.theme.primary};
  border-radius: 12px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${props => props.theme.primary};
  text-align: center;
  cursor: pointer;

  ${media.tablet`
    line-height: 47px;
    padding: 0 29px;

    &:hover {
      border-color: #00a0e1;
      background-color: #00a0e1;
    }
  `}

  ${media.desktop`
    padding: 0 29px;
    font-size: 17px;
    line-height: 47px;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.1s, color 0.1s;

    &:focus {
      outline: none;
    }

    &:hover {
      transition: background-color 0.1s 0.1s, color 0.1s 0.1s, border-color 0.1s 0.1s;
    }
  `}
`;

const TransparentButtonStyle = styled(Button)`
  color: ${props => props.theme.primary};
  background-color: transparent;

  &:hover {
    ${media.tablet`
      border-color: #00a0e1;
      color: #00a0e1;
      background-color: transparent;
    `}
  }
`;

const WhiteButtonStyle = styled(Button)`
  color: ${props => props.theme.primary};
  background-color: #fff;
  border-color: #fff;
  font-size: 21px;
  line-height: 52.5px;
  z-index: 99999;
  width: 80%;
  padding: 10px 29px;

  ${media.tablet`
    display: block;
    width: 190px;
    margin: 0 auto;
    font-size: 21px;
    line-height: 52.5px;
  `}

  ${media.desktop`
    font-size: 21px;
    line-height: 52.5px;
  `}

  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: #d33154;
  }
`;

const NavButtonStyle = styled(Button)`
  font-size: 10px;
  line-height: 30px;

  ${media.tablet`
    margin-left: 8px;
    line-height: 30px;
  `}

  ${media.desktop`
    line-height: 33px;
    font-size: 12px;
    margin-left: 10px;
  `}
`;

const FacebookButtonStyle = styled(Button)`
  font-size: 14px;
  display: block;
  width: 100%;
  margin: 0;
  margin-bottom: 16px;
  background: ${props => props.loading ? '#ccc' : '#0071af'};
  border-color: ${props => props.loading ? '#ccc' : '#0071af'};

  &:hover {
    background-color: ${props => props.loading ? '#ccc' : '#0071af'};
    border-color: ${props => props.loading ? '#ccc' : '#0071af'};
  }

  ${media.desktop`
    line-height: 47px;
    font-weight: 600;
    letter-spacing: .075em;
    color: #fff;
  `}
`;

const SignupButtonStyle = styled.input`
  font-family: 'proxima-soft', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.075em;
  color: #fff;
  margin: 0;
  padding: 15px 29px;
  border: 2px solid ${props => props.theme.primary};
  border-radius: 12px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${props => props.theme.primary};
  text-align: center;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.1s 0.1s, color 0.1s 0.1s,
    border-color 0.1s 0.1s;

  &:disabled {
    background-color: #ccc;
    border: 2px solid #ccc;
    cursor: not-allowed;
    &:hover {
      background-color: #ccc;
      border: 2px solid #ccc;
    }
  }

  ${media.tablet`
    line-height: 47px;
    padding: 0 29px;

    &:hover {
      border-color: #00a0e1;
      background-color: #00a0e1;
    }
  `} ${media.desktop`
    padding: 0 29px;
    font-size: 17px;
    line-height: 47px;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 0.1s, color 0.1s;

    &:focus {
      outline: none;
    }
  `};
`;

const TransparentNavButtonStyle = styled(NavButtonStyle)`
  color: ${props => props.theme.primary};
  background-color: transparent;
  border: ${props => props.hideBorder ? 'none' : `2px solid ${props => props.theme.primary};`};
  &:hover {
    ${media.tablet`
      border-color: #00a0e1;
      color: #00a0e1;
      background-color: transparent;
    `}
  }
`

const MobileNavigationButton = styled(Link)`
  font-family: 'proxima-soft', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.075em;
  color: #fff;
  margin-left: 10px;
  padding: 6px 29px;
  border: 2px solid ${props => props.theme.primary};
  border-radius: 12px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${props => props.theme.primary};
  text-align: center;
  cursor: pointer;
  display: none;
  
  @media (max-width: ${sizes.tablet + `px`}) {
    display: ${props => props.url && props.url.includes('jumpstart-your-childs-development') ? 'inline-block' : 'none'};
    margin-right: 8px;
  }
`;

export const FacebookButton = ({ children, ...props }) => (
  <FacebookButtonStyle {...props}>{children}</FacebookButtonStyle>
);

export const SignupButton = ({ children, ...props }) => (
  <SignupButtonStyle {...props} value={props.value}></SignupButtonStyle>
);

export const TransparentButton = ({ children, ...props }) => (
  <TransparentButtonStyle {...props}>{children}</TransparentButtonStyle>
);

export const WhiteButton = ({ children, ...props }) => (
  <WhiteButtonStyle {...props}>{children}</WhiteButtonStyle>
);

export const TransparentNavButton = ({ children, hideBorder, ...props }) => (
  <TransparentNavButtonStyle hideBorder={hideBorder} {...props}>{children}</TransparentNavButtonStyle>
);

export const NavButton = ({ children, ...props }) => (
  <NavButtonStyle {...props}>{children}</NavButtonStyle>
);

export const MobileNavButton = ({ children, ...props }) => (
  <MobileNavigationButton {...props}>{children}</MobileNavigationButton>
)

export default ({ children, ...props }) => <Button {...props}>{children}</Button>;
