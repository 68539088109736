import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/styles';

const StyledIcon = styled.svg`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid ${props => props.theme.primary};
  
  ${media.tablet`
    width: 70px;
    height: 70px;
  `} .circle {
    fill: #fff;
    transition: fill 0.2s ease;
  }
  .logo {
    fill: ${props => props.theme.primary};
    transition: fill 0.2s ease;
  }

  &:hover {
    border: 2px solid #fff;
    cursor: pointer;
    .circle {
      fill: ${props => props.theme.primary};
    }
    .logo {
      fill: #fff;
    }
  }
`;

const Wrapper = styled.span``;

export default ({ onClick, children }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon viewBox="0 0 77 77">
      <circle className="circle" cx="38.5" cy="38.5" r="38.5" />
      {React.Children.map(children, child =>
        React.cloneElement(child, { className: 'logo' })
      )}
    </StyledIcon>
  </Wrapper>
);
